import _ from "lodash";
import moment from "moment";
const INITIAL_STATE = {
  list: null,
  qlist: null,
  alist: null,
  agentlist: null
};
export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "QUEUE_STATUS_DATA": {
      const queueArray =
        action.payload !== null
          ? action.payload.queueStatus.map((x, index) => {
              return Object.assign(
                { key: index },
                {
                  abandoned: x.queue ? x.queue[0].abandoned : 0,
                  calls: x.queue ? x.queue[0].calls : 0,
                  completed: x.queue ? x.queue[0].completed : 0,
                  description: x.queue ? x.queue[0].description : 0,
                  event: x.queue ? x.queue[0].event : 0,
                  holdtime: x.queue ? x.queue[0].holdtime : 0,
                  max: x.queue ? x.queue[0].max : 0,
                  queue: x.queue ? x.queue[0].queue : 0,
                  queue_name: x.queue ? x.queue[0].queue_name : "NOT SET",
                  strategy: x.queue ? x.queue[0].strategy : 0,
                  talktime: x.queue ? x.queue[0].talktime : 0,
                  weight: x.queue ? x.queue[0].weight : 0,
                  call_waiting: "",
                  inOutbound: ""
                },
                {
                  agent: x.agent
                }
              );
            })
          : [];
      const agentsArray =
        action.payload !== null
          ? action.payload.queueStatus?.map(x => {
              if(x.agent === ""){
                return {};
              }
              return Object.assign(
                x.agent !== null
                  ? x.agent?.map(y => {
                      return Object.assign({
                        queue: x.queue[0].queue,
                        qname: x.queue[0].queue_name,
                        callstaken: y.callstaken,
                        extension: y.extension,
                        extnalias: y.extnalias,
                        incall: y.incall,
                        lastcall: moment
                          .unix(parseInt(y.lastcall))
                          .format("DD-MM-YYYY HH:mm:ss"),
                        lastpause: y.lastpause,
                        name: y.name,
                        paused: y.paused,
                        pausedreason: y.pausedreason,
                        penalty: y.penalty,
                        status: y.status,
                        wrapuptime: y.wrapuptime,
                        calleridnum: "",
                        channel: "",
                        channelstatedesc: "",
                        exten: "",
                        did: "",
                        seconds: "",
                        buyername: "",
                        campname: "",
                        campno: "",
                        uniqueid: "",
                        direction: ""
                      });
                    })
                  : {}
              );
            })
          : "";
      let agentFinalData = [];
      [agentsArray].forEach(element => {
        if (element.length > 0) {
          agentFinalData = element.filter(x => JSON.stringify(x) !== "{}");
        }
      });
      return {
        ...state,
        list: action.payload,
        qlist: queueArray,
        alist: agentFinalData
      };
    }
    case "AGENT_STATUS_DATA": {
      return { ...state, agentlist: action.payload };
    }

    case "AGENT_ACTIVATE_DEACTIVATE": {
      const queueArray =
        action.payload !== null
          ? action.payload.map((x, index) => {
              return Object.assign(
                { key: index },
                {
                  abandoned: x.queue ? x.queue[0].abandoned : 0,
                  calls: x.queue ? x.queue[0].calls : 0,
                  completed: x.queue ? x.queue[0].completed : 0,
                  description: x.queue ? x.queue[0].description : 0,
                  event: x.queue ? x.queue[0].event : 0,
                  holdtime: x.queue ? x.queue[0].holdtime : 0,
                  max: x.queue ? x.queue[0].max : 0,
                  queue: x.queue ? x.queue[0].queue : 0,
                  queue_name: x.queue ? x.queue[0].queue_name : "NOT SET",
                  strategy: x.queue ? x.queue[0].strategy : 0,
                  talktime: x.queue ? x.queue[0].talktime : 0,
                  weight: x.queue ? x.queue[0].weight : 0,
                  call_waiting: "",
                  inOutbound: ""
                },
                {
                  agent: x.agent
                }
              );
            })
          : [];

      const filterQueue = state.qlist.filter(
        x => parseInt(x.queue) !== parseInt(queueArray[0].queue)
      );
      const final = _.unionBy(filterQueue, queueArray);
      return { ...state, qlist: final };
    }
    default: {
      return state;
    }
  }
}
