import React from "react";
import { Route, Switch } from "react-router-dom";
import AsyncLoadable from "@/utils/AsyncLoadable";
import "animate.css";
import "./style/base.less";
import "./style/App.less";
const redirectUrl = process.env.REACT_APP_REDIRECT_URL;
const DefaultLayout = AsyncLoadable(() =>
  import(/* webpackChunkName: 'default' */ "./containers")
);
const View404 = AsyncLoadable(() =>
  import(/* webpackChunkName: '404' */ "./views/Others/404")
);
const View500 = AsyncLoadable(() =>
  import(/* webpackChunkName: '500' */ "./views/Others/500")
);
// const Login = AsyncLoadable(() =>
//   import(/* webpackChunkName: 'login' */ "./pages/Login")
// );
const ExternalLogin = AsyncLoadable(() =>
  import(/* webpackChunkName: 'login' */ "./pages/Login/ExternalLogin")
);
const ResetPassword = AsyncLoadable(() =>
  import(/* webpackChunkName: 'login' */ "./pages/Login/resetPassword")
);

const App = () => (
  <Switch>
    <Route path="/" exact render={() => window.location.replace(redirectUrl)} />
    <Route path="/500" component={View500} />
    {/* <Route path="/login" component={Login} /> */}
    <Route path="/redirect-login/:data" component={ExternalLogin} />
    <Route path="/response-password-reset" component={ResetPassword} />
    <Route path="/404" component={View404} />
    <Route component={DefaultLayout} />
  </Switch>
);

export default App;
