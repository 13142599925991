import { combineReducers } from "redux";
import auth from "./auth";
import campaign from "./campaign";
import didNumber from "./didNumber";
import extension from "./extension";
import status from "./status";
import report from "./report";
import block from "./block";
import log from "./log";
import loader from "./loader";
import share from "./share";
import account from "./account";
import dashboard from "./dashboard";
import notification from "./notification";
import voicemail from "./voicemail";
import socket from "./socket";
//import setting from "./setting";
const appReducer = combineReducers({
  auth,
  account,
  campaign,
  didNumber,
  extension,
  status,
  report,
  block,
  log,
  loader,
  share,
  dashboard,
  notification,
  socket,
  voicemail
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === "USER_LOGGED_OUT") {
    // we keep a reference of the keys we want to maintain
    // other keys will be passed as undefined and this will call
    // reducers with an initial state

    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
